<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <img
            width="100%"
            v-if="loading"
            :src="'./images/loader.gif'"
            class="mr-2"
            style="margin-top: -5px;"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right;">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>
          <router-link to="/accounts">
            <button class="btn btn-success btn btn-success rounded-pill mb-2">
              Back
            </button>
          </router-link>
          <router-link to="/skip-request-add"> </router-link>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Receive Skip List</h4>
          </div>
        </div>

        <div class="card-body" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(totalRequest)="loans">
                <span
                  v-if="loans.item.totalRequest != null"
                  @click="RequestView(loans)"
                  style="cursor: pointer;border-bottom: 2px solid red;width: 70px!important"
                  title="Click Here For Show Request List"
                  >{{ loans.item.totalRequest }}
                </span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VerficationModal from "./VerficationModal";
import axios from "axios";
export default {
  page: {
    title: "Collateral",
  },

  components: {
    VerficationModal,
  },
  data() {
    return {
      loans: [],
      alert: false,
      userId: "",
      loading: true,
      alertText: "",
      isModel: false,
      modalBodyText: "",
      actionType: "",
      modalTitle: "",
      buttonStatus: "",
      title: "Users",
      showModal: false,
      checkedNames: [],
      customerId: "",
      userObj: {},
      isShowModel: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      showDeleteModal: false,
      sortDesc: true,
      fields: [
        { key: "skipDetail.zipcode", label: "ZipCode" },
        { key: "skipDetail.size", label: "Size" },
        { key: "amount_asked", label: "Amount Asked £" },
        { key: "skipDetail.skip_share_percentage", label: "Skip Share Amount" },
        { key: "skipDetail.stripe_charges", label: "Transaction Charges" },
        {
          key: "amount_received",
          formatter: (value) => {
            return value ? value.toFixed(2) : 0;
          },
          label: "Received Amount £",
          sortable: true,
        },
        { key: "totalRequest", label: "# Req", sortable: true },
        { key: "skipDetail.expiry_date", label: "Expiry Date" },
      ],
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },
  methods: {
    RequestView(object) {
      localStorage.setItem("skipId", JSON.stringify(object.item.skipDetail.id));
      this.$router.push({ name: "specificpaidrequestlist" });
    },
    confirmationBulkAction(status) {
      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Active This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactive This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },
    closeModal(value) {
      this.isShowModel = value;
    },

    editSkipRequest(object) {
      localStorage.setItem("skipRequestEdit", JSON.stringify(object.item));
      this.$router.push({ name: "skip-request-edit" });
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    singlechangeStatus(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete";
    },
    singlActiveDeactive(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      if (this.userObj.item.status == "1") {
        this.actionType = "active";
      }
      if (this.userObj.item.status == "0") {
        this.actionType = "deactive";
      }
    },
    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-request-bulk-action", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["my-modal"].hide();
            this.getAllCustomer();
            this.alert = true;
            this.alertText = "Record Updated Successfully";
            this.checkedNames = [];
            this.hideAlert();
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomer() {
      try {
        const data = { userId: this.userId, request_from: "admin" };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/accounts-list-receive-device", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.loans = response.data.data;
              this.loading = false;
              return response.data;
            }
          });
      } catch (err) {
        // console.log(err);
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLoan() {
      this.$router.push({ name: "add-loan", params: { is_edit: false } });
    },

    editLoan(loan) {
      this.$router.push({
        name: "add-loan",
        params: { is_edit: true, loan_id: loan.item.id },
      });
    },
    // getAllCustomer(){
    //   ApisLists.getCustomers()
    //   .then(loans => {
    //    this.loans=loans;
    //   }).catch(error=>console.log(error))
    //     .finally(()=>{
    //     })
    // },
  },
  mounted() {
    this.totalRows = this.loans.length;
    this.userId = JSON.parse(localStorage.getItem("userId"));
    this.getAllCustomer();
    // this.getAllCustomer()
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
